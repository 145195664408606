import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
  startEvent: "DOMContentLoaded",
  disableMutationObserver: false,
  duration: 600,
  once: true,
});
AOS.refresh(true);

const header = document.getElementsByClassName(`header`)[0];
const mobileMenu = header.querySelector(".navbar__menu");
const headerHeight = header.clientHeight;

// Header scroll
if (window.scrollY > 0) {
  header.classList.add("scrolled");
}

document.addEventListener("scroll", () => {
  if (window.scrollY > 0) {
    header.classList.add("scrolled");
  } else if (window.scrollY === 0) {
    header.classList.remove("scrolled");
  }
});

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");

  if (header.classList.contains("active")) {
    header.style.height = mobileMenu.scrollHeight + headerHeight + "px";
  } else {
    header.style.height = headerHeight + "px";
  }

  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Parallax effect
function parallaxEffect(elements) {
  // Magic happens here
  function parallax(e) {
    let width = window.innerWidth / 2;
    let height = window.innerHeight / 2;

    let mouseX = e.clientX;
    let mouseY = e.clientY;

    let firstPos = `${10 - (mouseX - width) * 0.01}%`;
    let secondPos = `${10 - (mouseY - height) * 0.01}%`;

    elements.forEach((item, i) => {
      if (i % 2 === 0) {
        item.style.transform = `translate(${firstPos}, ${secondPos})`;
      } else {
        item.style.transform = `translate(-${firstPos}, -${secondPos})`;
      }
    });
  }

  document.addEventListener("mousemove", parallax);

  if (window.matchMedia("(max-width: 1024px)").matches) {
    document.removeEventListener("mousemove", parallax);
  }

  let flag = true;

  window.onresize = function () {
    if (flag && window.innerWidth < 1024) {
      document.removeEventListener("mousemove", parallax);
      elements.forEach((item) => (item.style.transform = ""));
      flag = false;
    }
  };
}

const parallaxItems = document.querySelectorAll(".parallax");
if (parallaxItems.length) {
  parallaxEffect(parallaxItems);
}

// Switcher
const switcher = document.querySelector(".switch");
if (switcher) {
  const inputs = switcher.querySelectorAll("input");
  const fill = switcher.querySelector(".fill");

  function elementPositioning(targetElement) {
    const elementPosition =
      targetElement.getBoundingClientRect().left -
      switcher.getBoundingClientRect().left;

    fill.style.width = targetElement.offsetWidth + "px";
    fill.style.left = elementPosition + "px";
  }

  elementPositioning(inputs[0]);

  inputs.forEach((input) => {
    input.addEventListener("change", (e) => elementPositioning(e.target));
  });
}

/* ------- Sliders ------- */
const rolesSection = document.querySelector(".roles-section");
if (rolesSection) {
  const rolesSlider = new Swiper(".roles-swiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    speed: 600,
    grabCursor: true,

    effect: "creative",
    creativeEffect: {
      limitProgress: 8,
      prev: {
        translate: [0, 0, -320],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 10px)", 0, 0],
        opacity: 1,
      },
    },

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Navigation
    navigation: {
      nextEl: ".roles-section .swiper-btn-next",
      prevEl: ".roles-section .swiper-btn-prev",
    },

    breakpoints: {
      550: {
        spaceBetween: 30,

        creativeEffect: {
          limitProgress: 8,
          prev: {
            translate: [0, 0, -320],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 30px)", 0, 0],
            opacity: 1,
          },
        },
      },
    },
  });

  SafariSwiper(rolesSlider);
}

const testimonialsSection = document.querySelector(".testimonials-section");
if (testimonialsSection) {
  const testimonialsSlides =
    testimonialsSection.querySelectorAll(".swiper-slide");
  const testimonialsSlider = new Swiper(".testimonials-swiper", {
    slidesPerView: "auto",
    spaceBetween: 14,
    speed: 600,
    grabCursor: true,
    centeredSlides: true,

    loop: testimonialsSlides.length >= 3,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      551: {
        spaceBetween: 40,
      },
      1150: {
        centeredSlides: false,
      },
    },
  });

  SafariSwiper(testimonialsSlider);
}

// Partners Swiper
const partnersSection = document.querySelector(".partners-slider-section");
if (partnersSection) {
  const partnerSlides = partnersSection.querySelectorAll(".swiper-slide");
  const partnersSlider = new Swiper(".partner-slider", {
    spaceBetween: 70,
    slidesPerView: "auto",
    observer: true,
    speed: 600,
    grabCursor: true,
    centeredSlides: partnerSlides.length > 6,
    loop: partnerSlides.length > 6,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Breakpoints
    breakpoints: {
      551: {
        spaceBetween: 106,
      },
    },
  });

  SafariSwiper(partnersSlider);
}

const teamSlider = document.querySelector(".team-slider");
if (teamSlider) {
  const swiper = new Swiper(teamSlider, {
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Breakpoints
    breakpoints: {
      551: {
        spaceBetween: 40,
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 60,
      },

      1024: {
        spaceBetween: 150,
        slidesPerView: 2,
      },
    },
  });

  SafariSwiper(swiper);
}
